import styled from "styled-components/macro"

import { Main as MainLayout } from "../Layout/NewLayout"

const TitledSection = ({children, title, flex}) => {
    const [firstWord, ...rest] = title?.split(' ') ?? ''

    
    return (
        <Section flex={flex}>
            <Title>
                {firstWord && <span>{`${firstWord} `}</span>}
                {rest.join(' ')}
            </Title>
            <Container>{children}</Container>
        </Section>
    )
}

export default TitledSection

const Section = styled.section`
    display: flex;
    color: inherit;
    flex-direction: column;
    ${ ({flex}) => flex ? `flex:${flex};` : '' }

    width: 100%;
    background-color: var(--white);

    ${MainLayout} > & {
        height: 100%;
    }
`

const Title = styled.div`
    text-transform: uppercase;
    font-size: 1.25rem;
    color: white;
    background-color: #3E644D;
    padding: .125em .8em .25em .8em;
    span {
        font-weight: bold;
    }
`
const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    color: inherit;
` 

