import React from 'react';
import styled from 'styled-components/macro';
import IconButton from './IconButton';
import { IoWarningOutline } from 'react-icons/io5';

const Tooltip = styled.div`
    position: absolute;
    left: 50%;
    top: calc(100% + 1rem);
    transform: translateX(-50%);
    border-radius: 0.5rem;
    border: 2px solid var(--dark-green);
    background-color: var(--pale-green);
    color: var(--white);
    opacity: 0;
    pointer-events: none;
    transition: opacity 300ms, width 300ms;
    text-align: center;
    padding: 0 0.5rem;
    width: max-content;
    max-width: 20ch;

    ::after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-bottom: 10px solid var(--dark-green);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
    }
`;

const Container = styled.div`
    position: relative;
    z-index: 200 !important;
    &:hover ${Tooltip} {
        opacity: 1;
    }
    svg {
        filter: drop-shadow(0px 1px 1px rgb(0 0 0 / 0.3));
    }

`;
const ValidationError = ({ message, className, size }) => {
    if (!message) return null;
    return (
        <Container className={className}>
            <IconButton
                icon={IoWarningOutline}
                size={size || '1em'}
                color='var(--danger-red)'
                forwardedAs='div'
            />
            <Tooltip>{message}</Tooltip>
        </Container>
    );
};

export default styled(ValidationError)``;
