import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';
import { DeleteButton } from '../Custom/IconButton';
import TextInput from '../Custom/TextInput';
import TimeZoneInput from '../Custom/TimeZoneInput';
import FieldsInput from './FieldsInput';
import ReactDatePicker from 'react-datepicker';
import Selector from '../Custom/Selector';
import turfDistance from '@turf/distance';

const ZoneInput = ({ name, nestIndex, ...rest }) => {
    const {
        register,
        control,
        watch,
        formState: { errors },
        removeZone,
        isCreate,
        allZones,
    } = useFormContext();

    function howFar(lat, long) {
        const watchLat = watch(`${name}.lat`);
        const watchLong = watch(`${name}.long`);

        if (!watchLat || !watchLong) return '';

        let dist = Math.round(turfDistance([watchLong, watchLat], [long, lat]));
        return ` (${dist}km away)`;
    }

    const zoneInitOptions = allZones?.reduce(
        (acc, curr) => ({ ...acc, [curr.zone_id]: curr.zone_name + howFar(curr.lat, curr.long) }),
        { 0: 'None' }
    );

    const handleDelete = () => removeZone(nestIndex);

    return (
        <Container {...rest}>
            <ZoneInfo>
                {/* <ZoneTitle>Zone {nestIndex + 1}</ZoneTitle> */}
                <TextInput
                    {...register(`${name}.name`)}
                    errors={errors}
                    width={'20ch'}
                    placeholder='Zone name'
                    label={!isCreate && 'Zone name'}
                />
                <TextInput
                    {...register(`${name}.lat`)}
                    errors={errors}
                    width={'10ch'}
                    placeholder='Latitude'
                    label={!isCreate && 'Latitude'}
                />
                <TextInput
                    {...register(`${name}.long`)}
                    errors={errors}
                    width={'10ch'}
                    placeholder='Longitude'
                    label={!isCreate && 'Longitude'}
                />
                <TextInput
                    {...register(`${name}.alt`)}
                    errors={errors}
                    width={'8ch'}
                    placeholder='Altitude'
                    label={!isCreate && 'Altitude'}
                />
                <Controller
                    name={`${name}.tz`}
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <TimeZoneInput
                            value={field.value}
                            onChange={field.onChange}
                            errors={errors}
                            name={field.name}
                            labelStyle='original'
                            getOptionLabel={data => data.value}
                            placeholder='Time zone'
                        />
                    )}
                />
                {isCreate && <DeleteButton size={'1.2em'} onClick={handleDelete} />}
            </ZoneInfo>
            {isCreate && (
                <ZoneInfo>
                    <Controller
                        key={errors}
                        name={`${name}.start_ts`}
                        control={control}
                        render={({ field }) => (
                            <div>
                                <ReactDatePicker
                                    selected={field.value}
                                    onChange={field.onChange}
                                    showTimeInput
                                    showIcon
                                    isClearable
                                    dateFormat='dd/MM/yy HH:mm'
                                    placeholderText='Sensor setup time'
                                    customInput={<TextInput width='19ch' />}
                                />
                            </div>
                        )}
                    />
                    <Selector
                        options={zoneInitOptions}
                        {...register(`${name}.init_zone`)}
                        defaultValue={0}
                    />
                </ZoneInfo>
            )}
            <FieldsContainer>
                <Controller
                    name={`${name}.fields`}
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                        <FieldsInput
                            onChange={field.onChange}
                            value={field.value}
                            name={field.name}
                        />
                    )}
                />
            </FieldsContainer>
        </Container>
    );
};

const Container = styled.fieldset`
    border: none;
    display: flex;
    flex-direction: column;
    border-radius: 1.5rem;
    background: var(--pale-green);
    border: 2px solid var(--dark-green);
    padding: 0.5rem;
    gap: 0.5rem;
`;

const ZoneInfo = styled.div`
    display: flex;
    /* z-index: 1; */
    gap: 0.5rem;
    padding: 0 0.5rem 0 1rem;
`;

const FieldsContainer = styled.div`
    width: 100%;
    height: 20.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    background-color: var(--green);
    border-radius: 0.5rem 0.5rem 1.2rem 1.2rem;
    border: 1px solid var(--dark-green);
    z-index: 0;
`;

export default ZoneInput;
