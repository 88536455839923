import React from 'react';
import styled from 'styled-components/macro';

import LeafletMap from './LeafletMap';
import { GeoJSON } from 'react-leaflet';

const MapPreview = props => {

    const polygons = props.fields
        .map(field => field.toGeoJSON())
        .flat()
        .filter(feature => feature.geometry.type === 'Polygon');

    return (
        <Container onClick={(e) => props.onClick(e)}>
            <LeafletMap
                extraClass='map-preview'
                center={props.center}
                tiles={['satellite']}
                scaleControl={false}
                zoomControl={false}
                scrollWheelZoom={false}
                dragging={false}
                keyboard={false}
                doubleClickZoom={false}
                boxZoom={false}
                zoomAnimation={false}
                recenterDeps={props.recenterDeps}
            >
                <GeoJSON data={polygons} key={JSON.stringify(polygons)} />
            </LeafletMap>

            <Overlay isEmpty={!polygons.length} />
        </Container>
    );
};

export default MapPreview;

const Container = styled.div`
    height: 100%;
    width: 100%;
    border-radius: inherit;

    position: relative;
    z-index: 0;

    ${LeafletMap} {
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
    }
`;

const Overlay = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    border-radius: inherit;
    background-color: #00000080;
    opacity: 0;
    transition: opacity 200ms ease;

    :hover {
        opacity: 1;
    }

    ::before {
        ${({ isEmpty }) => (isEmpty ? 'content: "Draw Fields";' : 'content: "Edit Fields"; ')}
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        color: var(--action-color);
        font-size: 2rem;
        letter-spacing: 0.3rem;
    }

    /* ::after {
        content: '';
        position: absolute;
        top: 1rem;
        bottom: 1rem;
        left: 1rem;
        right: 1rem;

        --border-color: var(--action-color);
        border-radius: inherit;
        background-image: repeating-linear-gradient(
                -40deg,
                var(--border-color),
                var(--border-color) 20px,
                transparent 20px,
                transparent 30px,
                var(--border-color) 30px
            ),
            repeating-linear-gradient(
                50deg,
                var(--border-color),
                var(--border-color) 20px,
                transparent 20px,
                transparent 30px,
                var(--border-color) 30px
            ),
            repeating-linear-gradient(
                140deg,
                var(--border-color),
                var(--border-color) 20px,
                transparent 20px,
                transparent 30px,
                var(--border-color) 30px
            ),
            repeating-linear-gradient(
                230deg,
                var(--border-color),
                var(--border-color) 20px,
                transparent 20px,
                transparent 30px,
                var(--border-color) 30px
            );
        background-size: 4px 100%, 100% 4px, 4px 100%, 100% 4px;
        background-position: 0 0, 0 0, 100% 0, 0 100%;
        background-repeat: no-repeat;
    } */
`;
