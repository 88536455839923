import React, { useContext, useState } from 'react';
import { useUserCtx } from './UserContext';


const ZoneContext = React.createContext();

export function useZoneCtx() {
	return useContext(ZoneContext);
}

export function ZoneProvider({ children }) {

	const {dfltZone} = useUserCtx()
	const initialZone = sessionStorage.getItem('zoneID') ? sessionStorage.getItem('zoneID') : String(dfltZone)
	
	console.log({dfltZone});

	const [zoneId, setZoneId] = useState(initialZone);
	const [zoneHourly, setZoneHourly] = useState(undefined);
	const [zoneDaily, setZoneDaily] = useState(undefined);


	function setZoneIdFunc(id) {
		if(!id) return
		setZoneId(String(id));
		sessionStorage.setItem('zoneID', id);
		setZoneDaily(undefined)
		setZoneHourly(undefined)
	}

	function setZoneHourlyData(data) {
		setZoneHourly(data);
	}
	function setZoneDailyData(data) {
		setZoneDaily(data);
	}

	const value = {
		zoneId,
		zoneHourly,
		zoneDaily,
		setZoneIdFunc,
		setZoneHourlyData,
		setZoneDailyData
	};

	return <ZoneContext.Provider value={value}>{children}</ZoneContext.Provider>;
}
